<template>
    <div class="edit-view">
        <page-title>
            <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
            <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id"> 删除 </el-button>
            <el-button @click="onSave" :loading="saving" type="primary">保存</el-button>
        </page-title>
        <div class="edit-view__content-wrapper">
            <div class="edit-view__content-section">
                <el-form
                    :model="formData"
                    :rules="rules"
                    ref="form"
                    label-width="80px"
                    label-position="right"
                    size="small"
                    style="max-width: 500px"
                >
                    <el-form-item label="项目" prop="projectId">
                       <project-select v-model="formData.projectId">
                        </project-select>
                    </el-form-item>
                    <el-form-item prop="name" label="名称">
                        <el-input v-model="formData.name"></el-input>
                    </el-form-item>
                    <el-form-item prop="pic" label="图片">
                        <single-upload v-model="formData.pic"></single-upload>
                    </el-form-item>
                    <el-form-item prop="remark" label="备注">
                        <el-input v-model="formData.remark"></el-input>
                    </el-form-item>
                    <el-form-item prop="sort" label="排序">
                        <el-input-number type="number" v-model="formData.sort"></el-input-number>
                    </el-form-item>
                    <el-form-item prop="type" label="展示位置">
                        <el-select v-model="formData.type" clearable filterable placeholder="请选择">
                            <el-option
                                v-for="item in typeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="link" label="跳转">
                        <el-switch v-model="formData.link"></el-switch>
                    </el-form-item>
                    <el-form-item prop="linkType" label="跳转类型" v-if="formData.link">
                        <el-select v-model="formData.linkType">
                            <el-option
                                v-for="item in linkTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="linkContent" label="跳转内容" v-if="formData.link">
                        <el-input v-model="formData.linkContent" placeholder="输入ID"></el-input>
                    </el-form-item>
                    <el-form-item class="form-submit">
                        <el-button @click="onSave" :loading="saving" type="primary"> 保存 </el-button>
                        <el-button @click="onDelete" :disabled="saving" type="danger" v-if="formData.id">
                            删除
                        </el-button>
                        <el-button @click="$router.go(-1)" :disabled="saving">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BannerEdit',
    created() {
        if (this.$route.query.id) {
            this.$http
                .get('banner/get/' + this.$route.query.id)
                .then(res => {
                    this.formData = res;
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    },
    data() {
        return {
            saving: false,
            formData: {
                projectId: this.$store.state.projectId,
                link: false
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }
                ],
                pic: [
                    {
                        required: true,
                        message: '请输入图片',
                        trigger: 'blur'
                    }
                ],
                sort: [
                    {
                        required: true,
                        message: '请输入排序',
                        trigger: 'blur'
                    }
                ],
                type: [
                    {
                        required: true,
                        message: '请输入展示位置',
                        trigger: 'blur'
                    }
                ],
                link: [
                    {
                        required: true,
                        message: '请输入跳转',
                        trigger: 'blur'
                    }
                ],
                linkType: [{ required: true, message: '请选择跳转类型' }],
                linkContent: [{ required: true, message: '请输入跳转内容' }],
                projectId: [{ required: true, message: '请选择项目' }]
            },
            typeOptions: [
                { label: '首页', value: 'HOME' },
                { label: '探索', value: 'DISCOVER' },
                { label: '铸造者', value: 'MINTER' },
                { label: '市场', value: 'MARKET' },
                { label: 'PC官方活动', value: 'PC_ACT' },
                { label: 'PC首页大图', value: 'PC_TITLE' }
            ],
            linkTypeOptions: [
                { label: '藏品/盲盒', value: 'collection' },
                { label: '铸造者', value: 'user' },
                { label: '活动', value: 'activity' }
            ]
        };
    },
    methods: {
        onSave() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.submit();
                } else {
                    return false;
                }
            });
        },
        submit() {
            let data = { ...this.formData };

            this.saving = true;
            this.$http
                .post('/banner/save', data, { body: 'json' })
                .then(res => {
                    this.saving = false;
                    this.$message.success('成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    console.log(e);
                    this.saving = false;
                    this.$message.error(e.error);
                });
        },
        onDelete() {
            this.$confirm('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/banner/del/${this.formData.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped></style>
